// Portfolio.js
import React from "react";
import { useTranslation } from "react-i18next";
import { MdOutlinePreview } from "react-icons/md";

const Portfolio = ({ projects }) => {
    const { t } = useTranslation();

    return (
        <div className="container mx-auto py-8 px-4">
            <div className="grid 2xl:grid-cols-6 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-8">
                {projects.map((project, index) => (
                    <div
                        key={index}
                        className="bg-zinc-400 dark:bg-zinc-600 shadow-xl rounded-lg overflow-hidden"
                    >
                        <div className="bg-gray-200">
                            <img
                                src={project.imageUrl}
                                alt={project.title}
                                className="w-full h-48 object-scale-down px-4 grayscale hover:scale-105 hover:grayscale-0 transition-all"
                            />
                        </div>
                        <div className="p-4">
                            <h3 className="text-lg poppins-semibold mb-2">
                                {project.title}
                            </h3>
                            <p className="text-sm poppins-regular mb-4">
                                {project.description}
                            </p>
                            <div className="flex flex-row justify-between">
                                <a
                                    href={project.link}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="flex flex-row justify-center items-center bg-gray-300 hover:bg-black text-black hover:text-white px-2 py-1 w-full text-center text-sm poppins-medium rounded-lg"
                                >
                                    <MdOutlinePreview className="me-1" size={'16px'} />
                                    {t('msg_works_see_live_button')}
                                </a>
                                {/* <a href={project.link} target='_blank' rel="noreferrer" className="text-indigo-500 hover:text-indigo-600 text-sm poppins-medium">Go Detail</a> */}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Portfolio;
