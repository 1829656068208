import React, { useState, useEffect } from 'react';

export const Typewriter = ({ texts, delay }) => {
    const [displayedText, setDisplayedText] = useState('');
    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const [currentCharIndex, setCurrentCharIndex] = useState(0);

    useEffect(() => {
        const writeText = () => {
            if (currentTextIndex < texts.length) {
                const currentText = texts[currentTextIndex];
                if (currentCharIndex < currentText.length) {
                    setDisplayedText((prev) => prev + currentText[currentCharIndex]);
                    setCurrentCharIndex((prev) => prev + 1);
                } else {
                    setDisplayedText((prev) => prev + '\n\n');
                    setCurrentTextIndex((prev) => prev + 1);
                    setCurrentCharIndex(0);
                }
            }
        };

        const intervalId = setInterval(writeText, delay);
        return () => clearInterval(intervalId);
    }, [texts, currentTextIndex, currentCharIndex, delay]);

    // Dil değiştiğinde bileşeni sıfırlamak için
    useEffect(() => {
        setDisplayedText('');
        setCurrentTextIndex(0);
        setCurrentCharIndex(0);
    }, [texts]);

    return <p className="whitespace-pre-line typewriter-text nova-font">{displayedText}</p>;
};

export const TypewriterRight = ({ texts, delay = 100 }) => {
    const [displayedText, setDisplayedText] = useState('');
    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const [currentCharIndex, setCurrentCharIndex] = useState(0);

    useEffect(() => {
        const writeText = () => {
            if (currentTextIndex < texts.length) {
                const currentText = texts[currentTextIndex];
                if (currentCharIndex < currentText.length) {
                    setDisplayedText((prev) => prev + currentText[currentCharIndex]);
                    setCurrentCharIndex((prev) => prev + 1);
                } else {
                    setDisplayedText((prev) => prev + '\n');
                    setCurrentTextIndex((prev) => prev + 1);
                    setCurrentCharIndex(0);
                }
            }
        };

        const intervalId = setInterval(writeText, delay);
        return () => clearInterval(intervalId);
    }, [texts, currentTextIndex, currentCharIndex, delay]);

    // Yeniden başlamak için currentIndex ve currentText sıfırlanır
    useEffect(() => {
        setCurrentTextIndex(0);
        setCurrentCharIndex(0);
        setDisplayedText('');
    }, [texts]);

    return <p className="whitespace-pre-line typewriter-text xl:text-lg text-sm nova-font md:text-end">{displayedText}</p>;
};