import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import emailjs from '@emailjs/browser';
import { RiMailSendLine } from "react-icons/ri";
import { useTranslation } from 'react-i18next';

function Contact() {
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const [disabled, setDisabled] = useState(false);
    const [alertInfo, setAlertInfo] = useState({
        display: false,
        message: '',
        type: '',
    });

    const toggleAlert = (message, type) => {
        setAlertInfo({ display: true, message, type });

        // Hide alert after 5 seconds
        setTimeout(() => {
            setAlertInfo({ display: false, message: '', type: '' });
        }, 5000);
    };

    const onSubmit = async (data) => {
        // Destrcture data object
        const { name, email, subject, message } = data;
        try {
            // Disable form while processing submission
            setDisabled(true);

            // Define template params
            const templateParams = {
                name,
                email,
                subject,
                message,
            };

            // Use emailjs to email contact form data
            await emailjs.send(
                import.meta.env.VITE_SERVICE_ID,
                import.meta.env.VITE_TEMPLATE_ID,
                templateParams,
                import.meta.env.VITE_PUBLIC_KEY,
            );

            // Display success alert
            toggleAlert(t('msg_contact_form_success'), 'success');
        } catch (e) {
            console.error(e);
            // Display error alert
            toggleAlert(t('msg_contact_form_error'), 'danger');
        } finally {
            // Re-enable form submission
            setDisabled(false);
            // Reset contact form fields after submission
            reset();
        }
    };

    return (
        <div className='w-full h-[calc(100vh-7.5rem)] justify-center items-center nova-font bg-contact bg-cover md:bg-top bg-no-repeat'>
            <div className="flex w-full h-full justify-center items-center">
                <div className="lg:w-3/5 w-full px-4 flex flex-col justify-center items-center grayscale-0!">
                    <div className="lg:px-24 md:px-12 px-8 lg:py-12 py-8 bg-gray-300 dark:bg-gray-800 rounded-3xl shadow-2xl">
                        <div className="flex flex-col justify-center items-center mb-4">
                            <h1 className="md:text-4xl text-2xl font-bold">{t('msg_contact_header')}</h1>
                            <p className="text-lg">{t('msg_contact_subheader')}</p>
                        </div>
                        <form id='contact-form' className='w-full flex flex-col gap-4 mt-8' onSubmit={handleSubmit(onSubmit)} noValidate >
                            {/* Row 1 of form */}
                            <div className='flex flex-col gap-4'>
                                <div className='col-6'>
                                    <input type='text' name='name'
                                        {...register('name', {
                                            required: {
                                                value: true,
                                                message: t('msg_contact_form_error_name'),
                                            },
                                            maxLength: {
                                                value: 30,
                                                message: t('msg_contact_form_error_max_length_name'),
                                            },
                                        })}
                                        className='p-2 rounded-lg w-full'
                                        placeholder={(t('msg_contact_form_name'))}
                                    ></input>
                                    {errors.name && (
                                        <span className='errorMessage'>
                                            {errors.name.message}
                                        </span>
                                    )}
                                </div>
                                <div className='col-6'>
                                    <input type='email' name='email'
                                        {...register('email', {
                                            required: true,
                                            pattern:
                                                /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                        })}
                                        className='p-2 rounded-lg w-full'
                                        placeholder={(t('msg_contact_form_email'))}
                                    ></input>
                                    {errors.email && (
                                        <span className='errorMessage'>
                                            {t('msg_contact_form_error_email')}
                                        </span>
                                    )}
                                </div>
                            </div>
                            {/* Row 2 of form */}
                            <div className='row formRow'>
                                <div className='col'>
                                    <input type='text' name='subject'
                                        {...register('subject', {
                                            required: {
                                                value: true,
                                                message: t('msg_contact_form_error_subject'),
                                            },
                                            maxLength: {
                                                value: 75,
                                                message: t('msg_contact_form_error_max_length_subject'),
                                            },
                                        })}
                                        className='p-2 rounded-lg w-full'
                                        placeholder={(t('msg_contact_form_subject'))}
                                    ></input>
                                    {errors.subject && (
                                        <span className='errorMessage'>
                                            {errors.subject.message}
                                        </span>
                                    )}
                                </div>
                            </div>
                            {/* Row 3 of form */}
                            <div className='flex-row row-auto'>
                                <div className='flex-col'>
                                    <textarea rows={3} name='message' {...register('message', { required: true, })}
                                        className='p-2 rounded-lg w-full'
                                        placeholder={t('msg_contact_form_message')}
                                    ></textarea>
                                    {errors.message && (
                                        <span className='errorMessage'>
                                            {t('msg_contact_form_error_message')}
                                        </span>
                                    )}
                                </div>
                            </div>

                            <button className='px-4 py-2 bg-black text-white rounded-xl hover:bg-white flex flez-row justify-center items-center border border-black hover:text-black' disabled={disabled} type='submit'>
                                <RiMailSendLine className='mr-1' size={'18px'} />
                                {t('msg_contact_form_button')}
                            </button>
                        </form>
                        {alertInfo.display && (
                            <div className={`alert alert-${alertInfo.type} alert-dismissible mt-5`} role='alert' >
                                {alertInfo.message}
                                <button type='button' className='btn-close' data-bs-dismiss='alert' aria-label='Close'
                                    onClick={() =>
                                        setAlertInfo({ display: false, message: '', type: '' })
                                    } // Clear the alert when close button is clicked
                                ></button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact