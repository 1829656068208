import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const DarkModeSwitcher = () => {
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
    const { t } = useTranslation();

    useEffect(() => {
        if (theme === 'dark') {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }
        localStorage.setItem('theme', theme);
    }, [theme]);

    const toggleTheme = () => {
        setTheme(theme === 'dark' ? 'light' : 'dark');
    };

    return (
        <button onClick={toggleTheme} className="p-2">
            {theme === 'dark' ? `🌞 ${t('mode_light')}` : `🌜 ${t('mode_dark')}`}
        </button>
    );
};

export default DarkModeSwitcher;
