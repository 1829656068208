import React from "react";
import Photo from "../img/photo.jpg";
import { TypewriterRight } from "../components/Typewriter";
// import { NavLink } from 'react-router-dom'
import {
    IoLogoFacebook,
    IoLogoInstagram,
    IoLogoLinkedin,
    IoLogoYoutube,
} from "react-icons/io5";
import { BsTwitterX } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Home() {
    const { t } = useTranslation();

    return (
        <div className="w-full md:h-[calc(100vh-7.5rem)] h-[calc(100vh-10rem)] justify-center items-center">
            <div className="flex w-full h-full justify-center items-center px-4 md:mt-0 mt-10">
                <div className="w-full md:h-full md:grid md:grid-cols-2 flex flex-col-reverse justify-center items-center md:gap-24 gap-8">
                    <div className="w-full flex flex-col justify-center md:items-end lg::gap-8 gap-2">
                        <h1 className="xl:text-6xl text-4xl nova-font-bold">
                            {t("msg_home_one")}
                        </h1>
                        <p className="xl:text-2xl text-xl md:text-end nova-font transition-all animate-pulse">
                            Full Stack Developer & Web Designer
                        </p>
                        <TypewriterRight
                            texts={[t("msg_home_three"), t("msg_home_four")]}
                            delay={50}
                            className="text-xs nova-font-bold transition-all"
                        />
                        <div className="flex flex-row w-full md:justify-end justify-center items-center mt-8 md:mb-0 mb-8 gap-4 text-gray-400 dark:text-gray-600 z-10">
                            <a
                                href={"https://x.com/kdrbn"}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <BsTwitterX
                                    className="hover:text-gray-700 dark:hover:text-gray-200 transition-all"
                                    size={"24px"}
                                />
                            </a>
                            <a
                                href={"https://instagram.com/kdrbn"}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <IoLogoInstagram
                                    className="hover:text-gray-700 dark:hover:text-gray-200 transition-all"
                                    size={"24px"}
                                />
                            </a>
                            <a
                                href={"https://www.linkedin.com/in/kadir-%C3%A7orbac%C4%B1o%C4%9Flu-821692101/"}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <IoLogoLinkedin
                                    className="hover:text-gray-700 dark:hover:text-gray-200 transition-all"
                                    size={"24px"}
                                />
                            </a>
                            <a
                                href={"https://www.youtube.com/@KadirCorbacoglu"}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <IoLogoYoutube
                                    className="hover:text-gray-700 dark:hover:text-gray-200 transition-all"
                                    size={"24px"}
                                />
                            </a>
                        </div>
                    </div>
                    <div className="h-full w-full flex md:justify-start justify-center items-center">
                        <div className="xl:ps-10 flex justify-center md:ps-20 ps-[25%] animated-picture-rtl xl:ml-[-15rem] lg:ml-[-8rem] md:ml-[-6rem] ml-[-5rem] overflow-hidden p-6">
                            <img
                                src={Photo}
                                alt="Kadir Çorbacıoğlu"
                                className="xl:w-[50%] lg:w-[80%] md:w-[100%] w-[90%] rounded-3xl grayscale border-4 border-gray-400 shadow-lg"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
