import React, { useCallback, useEffect, useState } from 'react';
import Photo3 from '../img/photo3.jpg';
import { Typewriter } from '../components/Typewriter';
import { useTranslation } from 'react-i18next';

function About() {
    const { t, i18n } = useTranslation();
    // const paragraphs = [
    //         "I am a software engineer with a passion for creating and developing software solutions. I have a strong background in software development and I am always eager to learn new technologies and methodologies. I am a team player and I am always looking for ways to improve my skills and knowledge.",
    //             "I have experience in developing web applications using React, Node.js, and Express. I have also worked on projects using Python, Java, and C#.",
    //             "I am currently working as a software engineer at a software company in Istanbul, Turkey. I am responsible for developing and maintaining web applications and APIs.",
    //     ]
    const [paragraphs, setParagraphs] = useState([]);

    const updateParagraphs = useCallback(() => {
        setParagraphs([
            t('msg_about_text1'),
            t('msg_about_text2'),
            t('msg_about_text3'),
            t('msg_about_text4'),
            t('msg_about_text5'),
        ]);
    }, [t]);

    useEffect(() => {
        updateParagraphs();
    }, [i18n.language, updateParagraphs]);

    return (
        <div className='container mx-auto lg:h-[calc(100vh-7.5rem)] justify-center items-center'>
            <div className="w-full md:h-full flex flex-row justify-center items-center">
                <div className="w-full px-4 mx-auto flex lg:flex-row flex-col justify-center items-center lg:gap-24 gap-8">
                    <div className="flex lg:w-1/4 w-full lg:justify-end justify-center lg:mt-0 mt-10 items-center">
                        <div className="flex justify-center items-center animated-picture-ltr overflow-hidden p-6">
                            <img src={Photo3} alt="Kadir Çorbacıoğlu" className='xl:w-[100%] lg:w-[75%] md:w-[30%] w-[70%] rounded-3xl grayscale border-4 border-gray-400 shadow-lg' />
                        </div>
                    </div>
                    <div className="flex lg:w-2/5 w-full flex-col justify-center items-start gap-2 text-justify pb-8">
                        <h1 className="w-full text-2xl nova-font-bold lg:text-start md:text-center text-center">{t('msg_about_header')}</h1>
                        <p className="w-full text-xl nova-font-bold transition-all">{t('msg_about_subheader')}</p>
                        <Typewriter texts={paragraphs} delay={20} key={i18n.language} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;


// import React from 'react'
// import Photo3 from '../img/photo3.jpg';
// import  { Typewriter } from '../components/Typewriter';
// import { useTranslation } from 'react-i18next';

// function About() {
//     const { t } = useTranslation();

//     const paragraphs = [
//         t('msg_about_text1'),
//         t('msg_about_text2'),
//         t('msg_about_text3'),
//         t('msg_about_text4'),
//         t('msg_about_text5'),
//     ];

//     return (
//         <div className='w-full mx-auto h-[calc(100vh-80px)] justify-center items-center'>
//             <div className="w-full h-full flex flex-row justify-center items-center">
//                 <div className="w-full mx-auto flex flex-row justify-center gap-24">
//                     <div className="flex w-1/4 justify-end items-center">
//                         <div className="flex justify-center items-center bg-gray-300 p-3 rounded-3xl shadow-2xl animated-picture-ltr">
//                             <img src={Photo3} alt="Kadir Çorbacıoğlu" className='w-full rounded-2xl grayscale' />
//                         </div>
//                     </div>
//                     <div className="flex w-2/5 flex-col justify-center items-start gap-2 text-justify">
//                         <h1 className="text-2xl nova-font-bold text-start">{t('msg_about_header')}</h1>
//                         <p className="text-xl nova-font-bold transition-all">{t('msg_about_subheader')}</p>
//                         <Typewriter texts={paragraphs} delay={20} />
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default About