import React from 'react'
import { useTranslation } from 'react-i18next';

function Footer() {
    const { t } = useTranslation();

    return (
        // mt-[-25px] w-full flex justify-center items-center text-xs
        <footer className="flex justify-center items-center w-full py-2 nova-font">
            <p className="text-sm">{t('footer')} &copy; {new Date().getFullYear()}</p>
        </footer>
    )
}

export default Footer