import React from "react";
import Portfolio from "../components/Portfolio";
// import { webProjects } from "../components/Projexts";
import { useTranslation } from "react-i18next";
import { useWebProjects } from "../components/Projexts";

function Works() {
    const { t } = useTranslation();
    const webProjects = useWebProjects();

    return (
        <div className="w-full justify-center items-center pt-8">
            <div className="flex flex-col w-full justify-center items-center">
                <h2 className="text-3xl poppins-semibold text-center">
                    {t("msg_works_header1")}
                </h2>
                <Portfolio projects={webProjects} />
                {/* <h2 className="text-3xl poppins-semibold text-center">
                    Softwares
                </h2>
                <Portfolio projects={webProjects} /> */}
            </div>
        </div>
    );
}

export default Works;
