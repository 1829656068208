import React, { useCallback, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DarkModeSwitcher from "./DarkModeSwitcher";
import { IoMenu, IoClose, IoHomeOutline, IoInformationCircleOutline, IoLanguageOutline } from "react-icons/io5";
import { IoIosContact } from "react-icons/io";
import { MdOutlineComputer } from "react-icons/md";
import { BsChatText } from "react-icons/bs";

function Navbar() {
    const [isOpen, setIsOpen] = useState(false);
    const [language, setLanguage] = useState("en");
    const { t, i18n } = useTranslation();

    const changeLanguage = useCallback(
        (lng) => {
            i18n.changeLanguage(lng);
        },
        [i18n]
    );

    useEffect(() => {
        changeLanguage(language);
    }, [language, changeLanguage]);

    return (
        <div className="w-full mx-auto shadow-2xl dark:shadow-white dark:shadow-xl">
            <nav className="container mx-auto h-20 flex felx-row justify-between items-center px-8">
                <div className="text-4xl brand-font2 !font-bold -rotate-12 overflow-hidden">
                    <NavLink to="/">Kadir Ç.</NavLink>
                </div>
                <div className="lg:flex flex-row items-center gap-4 poppins-medium hidden transition-all">
                    <NavLink
                        to="/"
                        className={
                            "hover:bg-gray-300 dark:hover:text-black px-2 py-1 rounded-xl"
                        }
                    >
                        {t("home")}
                    </NavLink>
                    <NavLink
                        to="/about"
                        className={
                            "hover:bg-gray-300 dark:hover:text-black px-2 py-1 rounded-xl"
                        }
                    >
                        {t("about")}
                    </NavLink>
                    <NavLink
                        to="/works"
                        className={
                            "hover:bg-gray-300 dark:hover:text-black px-2 py-1 rounded-xl"
                        }
                    >
                        {t("works")}
                    </NavLink>
                    <NavLink
                        to="/contact"
                        className={
                            "hover:bg-gray-300 dark:hover:text-black px-2 py-1 rounded-xl"
                        }
                    >
                        {t("contact")}
                    </NavLink>
                    {/* Language Options */}
                    <div className="flex">
                        <select
                            name="language"
                            id="language"
                            className="px-2 py-1 rounded-lg bg-transparent dark:bg-gray-800 outline-none hover:bg-gray-300 dark:hover:bg-gray-700"
                            value={language}
                            onChange={(e) => setLanguage(e.target.value)}
                        >
                            <option value="en">EN</option>
                            <option value="tr">TR</option>
                        </select>
                    </div>
                    <DarkModeSwitcher />
                </div>

                {/* Hamburger icon */}
                <div className="block lg:hidden">
                    <button className="flex flex-col items-center justify-center w-10 h-10" onClick={() => setIsOpen(!isOpen)}>
                        <IoMenu size={32} className={`${isOpen ? 'hidden' : 'block'} transition-all`} />
                        <IoClose size={32} className={`${isOpen ? 'block' : 'hidden'} transition-all`} />
                    </button>
                </div>
            </nav>

            <div className={`
                lg:hidden
                flex
                absolute
                top-20
                right-0
                flex-col
                justify-start
                pt-12
                ps-4
                items-start
                bg-gray-200
                dark:bg-gray-900
                w-1/2
                h-full
                z-50
                ${isOpen ? 'slide-in' : 'slide-out'} 
                `}
            >
                <div className="w-full flex flex-col gap-4 items-start">
                    <NavLink
                        to="/"
                        onClick={() => setIsOpen(false)}
                        className={
                            "hover:bg-gray-300 dark:hover:text-black px-2 py-1 rounded-xl inline-flex items-center"
                        }
                    >
                        <IoIosContact size={18} className="me-2" />
                        {t("home")}
                    </NavLink>
                    <NavLink
                        to="/about"
                        onClick={() => setIsOpen(false)}
                        className={
                            "hover:bg-gray-300 dark:hover:text-black px-2 py-1 rounded-xl inline-flex items-center"
                        }
                    >
                        <IoInformationCircleOutline size={18} className="me-2" />
                        {t("about")}
                    </NavLink>
                    <NavLink
                        to="/works"
                        onClick={() => setIsOpen(false)}
                        className={
                            "hover:bg-gray-300 dark:hover:text-black px-2 py-1 rounded-xl inline-flex items-center"
                        }
                    >
                        <MdOutlineComputer size={18} className="me-2" />
                        {t("works")}
                    </NavLink>
                    <NavLink
                        to="/contact"
                        onClick={() => setIsOpen(false)}
                        className={
                            "hover:bg-gray-300 dark:hover:text-black px-2 py-1 rounded-xl inline-flex items-center"
                        }
                    >
                        <BsChatText size={18} className="me-2" />
                        {t("contact")}
                    </NavLink>
                    <div className="w-full flex-row inline-flex items-center px-2">
                        <IoLanguageOutline size={24} className="" />
                        <select
                            name="language"
                            id="language"
                            className="w-full px-2 py-1 rounded-lg bg-transparent outline-none hover:bg-gray-300 dark:hover:bg-gray-700"
                            value={language}
                            onChange={(e) => { setLanguage(e.target.value); setIsOpen(false); }}
                        >
                            <option value="en">EN</option>
                            <option value="tr">TR</option>
                        </select>
                    </div>
                    <DarkModeSwitcher />
                </div>
                <div className="text-4xl brand-font2 !font-bold -rotate-12 hidden">
                    <NavLink to="/">Kadir Ç.</NavLink>
                </div>
            </div>
        </div>
    );
}

export default Navbar;
