import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationTR from './locales/tr/translation.json';

const resources = {
    en: {
        translation: translationEN
    },
    tr: {
        translation: translationTR
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'en', // başlangıç dili
        fallbackLng: 'en', // tercih edilen dil bulunamazsa kullanılacak dil
        interpolation: {
            escapeValue: false // React zaten XSS koruması yapıyor
        }
    });

export default i18n;
